<template>
    <div class="permission-groups">
        <div class="permission-groups-loading" v-if="deleteLoading || pageLoading">
            <CustomLoader />
        </div>
        <div class="card">
            <div class="card-header">
                <div class="card-title">
                    <h3 class="fw-bolder m-0 ">{{ $t("Permission Group") }}</h3>
                    <span class="text-with-bg ms-3">{{ permissionGroupsData.length }}</span>
                </div>
                <div class="permission-groups-right">
                    <div class="search-input">
                        <img src="/media/buying/icons/search-black.svg" alt="">
                        <el-input v-model="search" :placeholder="$t('Search Permission Group')"></el-input>
                    </div>
                    <router-link to="/business/settings/business-settings/permission-groups/add" class="btn main-btn" v-if="canChangeUserGroupPermissions">
                        {{ $t('Create Permission Group') }}
                    </router-link>
                </div>
            </div>

            <UserPermissionGroupTable
                :permission-groups="permissionGroupsData"
                :platform-type="PlatformTypeEnum.BUSINESS"
                :can-change-user-group-permissions="canChangeUserGroupPermissions"
                :search="search"
                :bank-customer-length="businessBankList.length"
                @onEditGroup="handleEditGroup"
                @onDeleteGroup="handleDeleteGroup"
            />
        </div>
    </div>
</template>

<script>
import store from "@/store";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination.vue";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader.vue";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";
import UserPermissionGroupTable from "@/buying-teams/shared-components/settings/UserPermissionGroupTable.vue";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { BusinessSettingsPermissionGroupsModel } from "@/store/models/business/BusinessTeamUserModel";

export default {
    name: "BusinessPermissionGroups",
    components: {
        UserPermissionGroupTable,
        CustomLoader,
        CustomPagination
    },
    data() {
        return {
            search: '',
            deleteLoading: false,
            pageLoading: true,
            permissionGroupsData: [],
            PlatformTypeEnum
        }
    },
    computed: {
        currentUser() {
            return store.getters.currentUser;
        },
        canChangeUserGroupPermissions() {
            return this.currentUser && this.currentUser.can(BusinessUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS);
        },
        businessData() {
            return store.getters.business;
        },
        businessBankList() {
            return store.getters.businessBanks;
        },
    },
    methods: {
        handleEditGroup(groupId) {
            this.$router.push({ name: "business-settings-permission-groups-edit", params: { id: groupId } })
        },
        handleDeleteGroup(groupId) {
            this.deleteLoading = true;
            store.dispatch('deleteBusinessPermissionsGroup', groupId)
                .then(() => {
                    this.permissionGroupsData = this.permissionGroupsData.filter(g => g.id !== groupId);
                })
                .finally(() => {
                    this.deleteLoading = false;
                });
        },
        async getBusinessPermissionGroups() {
            this.pageLoading = true;
            try {
                const res = await store.dispatch('getBusinessDetails', {
                    business_id: this.businessData.id
                });
                if (res.permission_groups && res.permission_groups.length) {
                    this.permissionGroupsData = res.permission_groups.map(item => {
                       return new BusinessSettingsPermissionGroupsModel(item);
                    });
                }
            } finally {
                this.pageLoading = false;
            }
        }
    },
    created() {
        if (!this.canChangeUserGroupPermissions) {
            this.$router.push('/business/settings/business-settings/overview');
        }
        this.getBusinessPermissionGroups();
    },
}
</script>

<style lang="scss">
.permission-groups {
    position: relative;
    .permission-groups-loading {
        position: absolute;
        z-index: 7;
        background: #ffffff82;
        border-radius: 11px;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        backdrop-filter: blur(4px);
    }
    .card {
        padding: 0;
        .card-header {
            display: flex;
            align-items: center;
            min-height: 89px;
            .card-title {
                margin: 0;
            }
        }
    }
    .search-input {
        position: relative;
        img {
            position: absolute;
            left: 26px;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            filter: invert(58%) sepia(5%) saturate(20%) hue-rotate(12deg) brightness(93%) contrast(94%);
        }
        .el-input__inner {
            border: 1px solid #E5E5E5;
            border-radius: 26px;
            background: transparent;
            padding-left: 47px;
            height: 43px;
            color: #2B2B2B;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;

            &::placeholder {
                color: #8B8B8B;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
            }

            @media (min-width: 400px) {
                width: 260px;
            }
        }
    }
    .permission-groups-right {
        display: flex;
        gap: 8px;
    }

    @media (max-width: 600px) {
        .permission-groups-right {
            flex-direction: column;
        }
    }
}
</style>
