<template>
    <div class="custom-pagination">
        <el-pagination
            v-model:current-page="currentPageValue"
            v-model:page-size="pageSize"
            :page-sizes="[5, 10, 50, 100]"
            :disabled="disabled"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :pager-count="5"
            :hide-on-single-page="hideOnSinglePage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
export default {
    name: "CustomPagination",
    props: {
        currentPage: Number,
        disabled: Boolean,
        total: Number,
        pageSize: {
            type: Number,
            default: 5
        },
        hideOnSinglePage: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            currentPageValue: 1

        };
    },
    created() {
        this.currentPageValue = this.currentPage || 1;
    },
    methods: {
        handleSizeChange(val) {
            this.$emit("pageSizeChange", val);
        },
        handleCurrentChange(val) {
            this.$emit("currentPageChange", val);
        }
    }
};
</script>

<style lang="scss">
.custom-pagination {
    .el-pagination {
        button,
        span:not([class*=suffix]) {
            height: 49px;
        }

        .el-pagination__total {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #12344D;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
        }

        .btn-prev, .btn-next, .el-pager li {
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            border-radius: 14px;
            min-width: 49px;
            height: 49px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            margin: 0;
        }

        .btn-prev {
            margin-right: 4px;
            padding-right: 6px;
        }

        .btn-next {
            margin-left: 4px;
            padding-left: 6px;
        }

        .el-pager {
            display: inline-flex;
            gap: 4px;

            li {
                &.active {
                    background: #ECEFFE;
                    border: 1px solid #E5E5E5;
                    color: #435BF4;
                }
            }
        }

        .el-pagination__jump {
            display: inline-flex;
            align-items: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #12344D;

            .el-pagination__editor {
                height: 49px;

                &.el-input {
                    width: max-content;

                    .el-input__inner {
                        background: #FFFFFF;
                        border: 1px solid #E5E5E5;
                        border-radius: 14px;
                        height: 49px;
                        min-width: 91px;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        color: #12344D;
                    }
                }
            }
        }

        .el-select {
            .el-input {
                .el-input__inner {
                    width: inherit;
                    height: 49px;
                    background: #FFFFFF;
                    border: 1px solid #E5E5E5;
                    border-radius: 14px;
                    text-align: left;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #12344D;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .el-pagination {
            button,
            span:not([class*=suffix]) {
                height: 40px;
            }

            .btn-prev, .btn-next, .el-pager li {
                height: 40px;
                min-width: 40px;
                font-size: 14px;
            }

            .el-select .el-input .el-input__inner {
                height: 40px;
            }

            .el-pagination__jump {
                .el-pagination__editor {
                    height: 40px;

                    &.el-input {
                        .el-input__inner {
                            height: 40px;
                            min-width: 40px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        .el-pagination {
            .btn-prev, .btn-next, .el-pager {
                display: none;
            }

            .el-pagination__jump {
                margin-left: 0;
            }
        }
    }
}
</style>
