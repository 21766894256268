export enum BusinessUserPermissionTypeEnum {
    //User
    PU_INVITE_USER = "pu_invite_user",
    CREATE_FEEDBACK_SESSION = "pu_create_feedback_session",
    REPLY_FEEDBACK_SESSION = "pu_reply_feedback_session",
    SHARE_REQUESTS_WITH_BANK = "pu_share_request",
    SHARE_IDEAS_WITH_BANK = "pu_share_idea",
    COMMENT_ON_REQUESTS = "pu_comment_request",
    COMMENT_ON_IDEAS = "pu_comment_idea",
    OPEN_CLOSE_REQUESTS_AND_IDEAS = "pu_open_close_request",
    SHARE_FULL_NAME_WITH_BANK = "pi_share_fullname",
    SHARE_JOB_TITLE_WITH_BANK = "pi_share_jt",
    SHARE_PROFILE_PICTURE_WITH_BANK = "pi_share_pp",
    SHARE_COUNTRY_WITH_BANK = "pi_share_cnt",

    //Admin
    PA_INVITE_USER = "pa_invite_user",
    EDIT_USER = "pa_edit_user",
    PROMOTE_DEMOTE_USER = "pa_promote_demote_user",
    DELETE_USER = "pa_delete_user",
    EDIT_BUSINESS = "pa_edit_business_settings",
    CHANGE_USER_GROUP_PERMISSIONS = "pa_change_user_group_permissions",
    CHANGE_ADMIN_PERMISSIONS = "pa_change_admin_permissions",
    INVITE_ADMIN = "pa_invite_admin",
    EDIT_ADMIN = "pa_edit_admin",
    DELETE_ADMIN = "pa_delete_admin",
}
