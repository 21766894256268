<template>
    <div class="permission-groups-table">
        <el-table :data="displayData" @row-click="rowClick" :row-class-name="canChangeUserGroupPermissions ? 'has-access' : ''">
            <el-table-column prop="name" :label="$t('Name')" min-width="300"></el-table-column>
            <el-table-column prop="users" :label="$t('Users')" min-width="150"></el-table-column>
            <el-table-column prop="productAreas" :label="$t('Product Areas')" min-width="150"></el-table-column>
            <el-table-column prop="countries" :label="$t('Countries')" min-width="150"></el-table-column>

            <el-table-column
                v-if="platformType === PlatformTypeEnum.BUSINESS"
                prop="banks"
                :label="$t('Banks')"
                min-width="150"
                v-slot="{ row }"
            >
                {{ bankCustomerLength - row.banks }}
            </el-table-column>
            <el-table-column v-if="platformType === PlatformTypeEnum.BANK" prop="customers" :label="$t('Customers')" min-width="150"></el-table-column>

            <el-table-column class-name="actions" prop="actions" :label="$t('Actions')" width="120" fixed="right" v-slot="{ row }" v-if="canChangeUserGroupPermissions">
                <el-dropdown trigger="click" class="mr-2">
                    <span class="el-dropdown-link bank-teams-table--link-icon">
                        <i class="bi bi-three-dots fs-1 fw-bolder"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="handleEditGroup(row.id)">{{ $t("Edit") }}</el-dropdown-item>
                            <el-dropdown-item @click="handleDeleteGroup(row.id)">{{ $t("Delete") }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-table-column>
        </el-table>

        <CustomPagination
            class="mt-4 mb-4 ms-7"
            :current-page="page"
            :total="getFilteredData.length"
            :page-size="pageSize"
            :hide-on-single-page="false"
            @currentPageChange="page = $event"
            @pageSizeChange="pageSize = $event"
        />
    </div>
</template>

<script>
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination.vue";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import NotificationService from "@/buying-teams/services/NotificationService";

export default {
    name: "UserPermissionGroupTable",
    components: { CustomPagination },
    props: {
        permissionGroups: Array,
        platformType: {
            type: [String, PlatformTypeEnum]
        },
        canChangeUserGroupPermissions: {
            type: Boolean,
            default: false,
        },
        search: {
            type: String,
            default: '',
        },
        bankCustomerLength: {
            type: Number,
            default: 0,
        }
    },
    emits: ['onEditGroup', 'onDeleteGroup'],
    data() {
        return {
            page: 1,
            pageSize: 5,
            PlatformTypeEnum,
        }
    },
    computed: {
        displayData() {
            return this.getFilteredData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        },
        getFilteredData() {
            let data = [ ...this.permissionGroups ];
            return this.search ? data.filter((item) => {
                return item.name.toLowerCase().includes(this.search.toLowerCase())
            }) : data;
        },
    },
    methods: {
        rowClick(row, column) {
            if (!this.canChangeUserGroupPermissions) return;
            if (column.property !== 'actions') {
                this.handleEditGroup(row.id);
            }
        },
        handleEditGroup(groupId) {
            this.$emit('onEditGroup', groupId);
        },
        handleDeleteGroup(groupId) {
            NotificationService.swalConfirmDialog({
                title: this.$t("Delete Group"),
                text: this.$t("Are you sure you want to remove the Permission Group?"),
                confirmButtonText: this.$t("Delete"),
                cancelButtonText: this.$t("Cancel"),
                customClass: "confirm-delete-group"
            }).then((res) => {
                if (res.value) {
                    this.$emit('onDeleteGroup', groupId);
                }
            });
        }
    }
}
</script>

<style lang="scss">
.permission-groups-table {
    .el-table th.el-table__cell {
        padding: 0;
        height: 74px;
        background: #FAFAFA;
    }
    .el-table .el-table__cell {
        padding: 0;
        height: 74px;
    }
    .el-table {
        tr {
            background: #FAFAFA;
        }
        td, th {
            &:first-child .cell {
                padding-left: 32px;
            }
        }
        td, th {
            &:last-child .cell {
                padding-right: 32px;
                text-align: center;
            }
        }
        tbody {
            .el-table__row.has-access {
                &.hover-row {
                    .el-table__cell {
                        background: #f5f7fa !important;
                    }
                }
                .el-table__cell:not(.actions) {
                    cursor: pointer;
                }
            }
        }
    }
    .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
        background: #fff;
    }
    .el-table__body-wrapper {
        tr {
            background: #fff;
        }
    }
    .el-table__header {
        tr th .cell {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
        }
    }
    .el-table td.el-table__cell {
        background: #fff;
        &:first-child div {
            color: #2B2B2B;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }
        div {
            color: #8B8B8B;
            font-size: 14px;
            font-weight: 600;
            line-height: 23px;
        }
    }
}
.swal2-popup.swal2-modal.confirm-delete-group {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.30);
    width: 490px;
    padding: 24px;
    .swal2-header {
        align-items: flex-start;
        padding: 0;
        .swal2-title {
            margin: 0;
            color: #000;
            font-size: 24px;
            font-weight: 700;
            line-height: 42.72px;
        }
    }
    .swal2-content {
        padding: 0;
        .swal2-html-container {
            text-align: left;
            margin-top: 16px;
            color: #595959;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
        }
    }
    .swal2-actions {
        margin-top: 40px;
        margin-bottom: 0;
        flex-direction: row-reverse;
        gap: 20px;

        .swal2-styled {
            margin: 0;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            border-radius: 30px;
            padding: 15px;
            min-width: 143px;

            &.swal2-confirm {
                color: #FFF;
                background: #EA3333;
                box-shadow: 0px 6px 43px 0px rgba(0, 0, 0, 0.13), 0px 4px 9px 0px rgba(0, 0, 0, 0.19);
                border: 1px solid #EA3333 !important;
            }
            &.swal2-cancel {
                color: #435BF4;
                border: 1px solid #435BF4;
                background: transparent;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
    .swal2-icon.swal2-question {
        display: none !important;
    }
}
</style>
